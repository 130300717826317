$opc_update: true;
$appt_booking: true;

$loyalty_v2: true;
//loyalty font-families
$loyalty-tstar-font: "tstar_mono_roundregular", Helvetica, Arial, Sans-Serif;
$loyalty-ano-bold-font: 'ano-bold_regular', Helvetica, Arial, sans-serif;

$tstar-font: roboto-condensed-regular, Helvetica, Arial, Sans-Serif;
$tstar-bold-font: roboto-condensed-bold, Helvetica, Arial, Sans-Serif;
$ano-bold_regular-font: roboto-condensed-bold, Helvetica, Arial, Sans-Serif;
$times-font: TimesNewRoman, 'Times New Roman', Times, serif;
$roboto-condensed-bold-font: roboto-condensed-bold, Helvetica, Arial, Sans-Serif;
$roboto-condensed-regular-font: roboto-condensed-regular, Helvetica, Arial, Sans-Serif;
$body-font-size: 12.5px;
$password_strengthen: true;

// 2021 styling refresh for MPP.
$cr21_mpp: false;